import io from "socket.io-client";
import global from "../components/Global";

//############################################################################# Socket

const url = (process.env.NODE_ENV === "production") ? "https://game-21.onrender.com" : "http://localhost:3001";
const socket = io(url);

//############################################################################# Connect / Disconnect

socket.on("onConnect", (game, desk, team1, team2) => {
    global.client.id = socket.id;
    setData(game, desk, team1, team2);
});

socket.on("onDisconnect", (game, desk, team1, team2) => {
    setData(game, desk, team1, team2);
});

//############################################################################# Update

socket.on("data", setData);

function setData(gameData, deskData, team1Data, team2Data, audio, delay) {
    if (gameData) global.game = gameData;
    if (deskData) global.desk = deskData;
    if (team1Data) global.team1 = team1Data;
    if (team2Data) global.team2 = team2Data;

    if (global.desk.turn) {
        global.team1.turn = global.desk.turn === 1;
        global.team2.turn = global.desk.turn === 2;
    }

    const {client, game, team1, team2, play} = global;

    if (game.status === "lobby") global.infobox.showTo = null;
    if (game.status === "running" || game.status === "paused") global.show.settings = false;

    global.client.isInTeam = team1.ids.includes(client.id) || team2.ids.includes(client.id);

    if (audio) play(audio, delay);
}

//############################################################################# Info Box

socket.on("infobox", (showTo = null, headline = null, text = null, button = null, action = null) => {
    global.infobox = { showTo, headline, text, button, action };
});

//############################################################################# Audio

socket.on("audio", (name, delay, data) => {
    const {client, play} = global;

    if (name === "chooseWinner") {
        name = (data.ids?.includes(client.id)) ? "round_winner" : "round_loser";
    }

    if (name) play(name, delay);
});

//############################################################################# Export

export {socket};